import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import s from "./Login.module.scss";
import BigOrangeBtn from "../CommonComponents/BigOrangeBtn/BigOrangeBtn";
import {Link, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import AxiosClient from "../../axios-client";
import TextField from "../CommonComponents/form/TextField/TextField";

export default function ResetPassword() {
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState(null);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const validationSchema = Yup.object({
        password: Yup.string().required("Required").min(8, 'Minimum 8 characters'),
        password_confirmation: Yup.string().required("Required").min(8, 'Minimum 8 characters'),
    })

    const onSubmit = async (values) => {
        const payload = {
            token: window.location.pathname.split('/')[2],
            email: window.location.search.split('=')[1],
            password: values.password,
            password_confirmation: values.password_confirmation,
        }
        setErrors(null);
        await AxiosClient.post('/reset-password', payload).then(({data}) => {
            navigate('/login');
        }).catch(err => {
            setMessage(err.response.data.message);
        })
    }

    const initialValues = {
        password: '',
        password_confirmation: '',
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}
                validateOnChange={false} validateOnBlur={false}>
            {
                ({isSubmitting}) => {
                    return <Form noValidate={true}>

                        <div className="login-signup-form animated fadeInDown">
                            <div className="form">
                                <h3 className="title">{t('change_password')}</h3>

                                {errors &&
                                    <div className='alert'>
                                        {Object.keys(errors).map(key => (
                                            <p key={key}>{errors[key][0]}</p>
                                        ))}
                                    </div>
                                }

                                <div className={s.wrapper}>
                                    <TextField name='password' placeholder={t('password')} type="password"/>
                                </div>
                                <div className={s.wrapper}>
                                    <TextField name='password_confirmation' placeholder={t('password_confirmation')}
                                               type="password"/>
                                </div>
                                <BigOrangeBtn disabled={isSubmitting}>{t('confirm')}</BigOrangeBtn>
                                <div className="message">
                                    <div>{message}</div>
                                    <Link to="/login">{t('return_to_login')}</Link>
                                </div>
                            </div>
                        </div>
                    </Form>
                }
            }
        </Formik>
    );
}
