import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import s from "./Login.module.scss";
import BigOrangeBtn from "../CommonComponents/BigOrangeBtn/BigOrangeBtn";
import {Link} from "react-router-dom";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import AxiosClient from "../../axios-client";
import TextField from "../CommonComponents/form/TextField/TextField";

export default function ForgotPassword() {
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState(null);

    const {t} = useTranslation();

    const validationSchema = Yup.object({
        email: Yup.string().required('Required').email('Wrong email format'),
    })

    const onSubmit = async (values) => {
        const payload = {
            email: values.email,
        }
        setErrors(null);
        await AxiosClient.post('/forgot-password', payload).then(({data}) => {
            setMessage(t('reset_email_sent'));
        }).catch(err => {
            setMessage(err.response.data.message);
        })
    }

    const initialValues = {
        email: '',
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}
                validateOnChange={false} validateOnBlur={false}>
            {
                ({isSubmitting}) => {
                    return <Form noValidate={true}>

                        <div className="login-signup-form animated fadeInDown">
                            <div className="form">
                                <h3 className="title">{t('forgot_password')}</h3>

                                {errors &&
                                    <div className='alert'>
                                        {Object.keys(errors).map(key => (
                                            <p key={key}>{errors[key][0]}</p>
                                        ))}
                                    </div>
                                }

                                <div className={s.wrapper}>
                                    <TextField name='email' placeholder={t('email')} type="email"/>
                                </div>
                                <BigOrangeBtn disabled={isSubmitting}>{t('send_reset_email')}</BigOrangeBtn>
                                <div className="message">
                                    <div>{message}</div>
                                    <Link to="/login">{t('return_to_login')}</Link>
                                </div>
                            </div>
                        </div>
                    </Form>
                }
            }
        </Formik>
    );
}
