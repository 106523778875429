import {Form, Modal} from "react-bootstrap";
import {Star, StarFill} from "react-bootstrap-icons";
import React, {useState} from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useStateContext} from "../../../../contexts/ContextProvider";
import s from './FirstReviewModal.module.scss';
import AxiosClient from "../../../../axios-client";
import {t} from "i18next";
import {backendUrl, frontendUrl} from "../../../../utils/config";
import BigOrangeBtn from "../../../CommonComponents/BigOrangeBtn/BigOrangeBtn";
import {useNavigate} from "react-router-dom";

const MySwal = withReactContent(Swal);

const FirstReviewModal = ({show, setShow, tutor, review}) => {
    const {type, user, setUser} = useStateContext();
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState({
        feedback: '',
        rating: 0,
    });

    const scrollTo = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
        handleClose();
    }

    const handleClose = () => setShow(false);

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            ...data,
            tutor_id: tutor.id,
            student_id: user.id,
        }

        AxiosClient.post(`student/leave-first-review`, payload)
            .then(({data}) => {
                console.log("Success:", data);
                setErrors(null);
                setLoading(false);
                window.location.reload()
            })
            .catch((error) => {
                console.log("Error:", error);
                setErrors(null);
                setLoading(false);
            });
    };

    const moveToLogin = () => {
        handleClose();
        navigate('/login');
    }

    return (user ? (
                <Modal show={show} onHide={handleClose}>
                    <Form onSubmit={onSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Leave Your Feedback</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className="d-flex justify-content-center align-items-center my-3">
                                    {tutor.avatar ?
                                        <img className="avatar-wrap" src={`${backendUrl}/storage/${tutor.avatar}`}
                                             alt="avatar"/>
                                        : <img className="avatar-wrap" src={`${frontendUrl}/no-image.png`} alt="avatar"/>
                                    }
                                </div>
                            </div>
                            {tutor.calendar && tutor.calendar.some(item => item.student_id === user?.id) ?
                                (<div>
                                    <div className="mb-3 text-center fw-bold">Yoy have lesson
                                        with {tutor.name} {tutor.lastname}</div>
                                    <div className={s.starRating}>
                                        {[1, 2, 3, 4, 5].map((i) => (
                                            <span key={i} name='rating' onClick={() => setData({...data, rating: i})}>
                    {i <= data.rating ? <StarFill fill={'gold'}/> : <Star/>}
                  </span>
                                        ))}
                                    </div>
                                    <Form.Group className="mb-3" controlId="13">
                                        <Form.Label className="fw-bold">Leave your feedback</Form.Label>
                                        <Form.Control as="textarea" rows={3}
                                                      value={data?.feedback}
                                                      onChange={ev => setData({...data, feedback: ev.target.value})}
                                                      placeholder={t('note_private_teacher_placeholder')}
                                        />
                                    </Form.Group>
                                </div>) :
                                <div className="text-center">To write a review, you must be a student
                                    of {tutor.name} {tutor.lastname}</div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            {tutor.calendar && tutor.calendar.some(item => item.student_id === user?.id) ?
                                <BigOrangeBtn type="submit">
                                    {t('submit')}
                                </BigOrangeBtn> :
                                <BigOrangeBtn onClick={scrollTo}>
                                    Book a lesson with {tutor.name} {tutor.lastname}
                                </BigOrangeBtn>}
                        </Modal.Footer>
                    </Form>
                </Modal>)
            : (<Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Leave Your Feedback</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3 text-center fw-bold">
                            You need to log in to proceed
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <BigOrangeBtn onClick={moveToLogin}>
                            Log in
                        </BigOrangeBtn>
                    </Modal.Footer>
                </Modal>
            )
    );
};

export default FirstReviewModal;



